const OPENAI_API = 'https://openaitimesheet.azurewebsites.net/'
// const ACS_API = 'https://app-cog-acs-eastus-001.azurewebsites.net/'
const VERTEX_API = 'https://app-videosummarize-eastus.azurewebsites.net/'
const OPENAI_API_NEW = 'https://app-prod-ashchatgpt.azurewebsites.net/'
const ACS_API = 'https://app-prod-ashchatgpt.azurewebsites.net/'
export const openaiEnv = {
    OPENAI_API: OPENAI_API,
    ACS_API: ACS_API,
    VERTEX_API:VERTEX_API,
    OPENAI_API_NEW : OPENAI_API_NEW
}