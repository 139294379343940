
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';

import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IDropdownSettings} from 'ng-multiselect-dropdown';

import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';

import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {UploadService} from '../../_services/upload.service';
import {UserService} from './../../_services/user.service';
import {AppConfig} from './../../../app/app.config';
import {SweetAlertHelper} from '../../_helpers/sweet.alert.helper';
import {Message} from '../../_locale/messages';

import * as S3 from 'aws-sdk/clients/s3';
import { Observable } from 'rxjs';


declare let $: any;
@Component({
  selector: 'patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.scss'],
})
export class PatientHistory implements OnInit {
  private pdfApiUrl = 'https://openaitimesheet.azurewebsites.net/reports';
  private videoApiUrl = 'https://app-videosummarize-eastus.azurewebsites.net/analyze_video';
  private imageApiUrl = 'https://app-videosummarize-eastus.azurewebsites.net/analyze_image';

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  childRoute: string;
  loggedInUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  showDataVal: number = 1;  
  loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
  message = Message;
  showTableData = 1;
  showLoader = false;
  delegateId = localStorage.getItem('delegateId');
  dtOptions: DataTables.Settings = {};
  profileObj : any = {};
  documentsList : any = [];
  summaryPopup : any = false;
  patientDetails : any = [];
  patientId : string;
  bmi: any;
  patientsTreatingDoctorsList : any = [];
  patientsNonTreatingDoctorsList : any = [];
  documentsLength: any;
  resultJsonObj: any = {};
  selectedAiSummary: any = {};
  summaryAnswer: string;
  isSummaryAnswer: boolean = false;
  questionToSummary: string;
  confirmSummaryPopup: any = false;
  documentComment: string;
  documentCommentDetails: any[] = [];
  documentCommentDetailsLength: boolean = false;
  openFacilityDetailsPopup: boolean = false;
  facilityDetailsArray: any = {};
  documentCategories: any;
  practiceDocumentsFlag : boolean = false;
  practiceDocumentsList : any = [];
  allDocumentsFlag : boolean = false;
  adminCommentsList: boolean = false;
  dictationList: any = [];
  LastName: string;
  FirstName: string;


  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router,
    private http: HttpClient,
    public userService: UserService
  ) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    this.patientId = this.activatedRoute.snapshot.paramMap.get('demographyUserId');
  }

  ngOnInit() {
    this.loggedInUserId = localStorage.getItem('delegateId') ? localStorage.getItem('delegateId') : this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.dtOptions = { order: []};
    this.getPatientDetailsById()
    this.showData(this.showDataVal)

    
    
    this.patientDetails.BMI = this.BMI_calculator();
    this.bmi = this.BMI_calculator()

    this.fnFindAllDocuments('0');
    // this.getDocumentCategories()
  
  }

  BMI_calculator(){
    const totalHeightInches = this.patientDetails.HeightInFeet * 12 + '.' + (this.patientDetails.HeightInInch ? this.patientDetails.HeightInInch : 0);
    let BMI = (this.patientDetails.Weight/(+totalHeightInches*+totalHeightInches)) * 703
    return parseFloat(BMI.toFixed(2));
  }

  

  
  showData(selectedVal) {
    this.showDataVal = selectedVal;
  }


  
  showD2DEmailRequest(value) {
    if (value == 1) {
      this.showTableData = value;
    } else if (value == 2) {
      this.showTableData = value;
    } else if (value == 3) {
      this.showTableData = value;
    }
  }


  async findPatientDictationFiles() {
    this.dictationList = [];
    const finalData = {
      patientId: localStorage.getItem('loggedInUserId'),
      companySlug: ConstantHelper.COMPANY_SLUG,
    };
    this.showLoader = true;
    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.findPatientDictationFiles, finalData, this.httpOptions)
      .subscribe(
        (res: any) => {
          if(res.commandResult.data.Dictations.length > 0){
            this.dictationList = res.commandResult.data.Dictations;
            this.showLoader = false;
            this.adminCommentsList = true;
          }
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
      this.showLoader = false;
  }

  openSummaryPopup(documentId){
    this.selectedAiSummary = this.documentsList.find(
      (e) => e.RecordId == documentId
    );
    this.summaryPopup = true;
  }

  openConfirmSummaryPopup(documentId){
    this.selectedAiSummary = this.documentsList.find(
      (e) => e.RecordId == documentId
      );
    this.confirmSummaryPopup = true;
  }

  openRejectSummaryPopup(documentId){
    this.selectedAiSummary = this.documentsList.find(
      (e) => e.RecordId == documentId
    );
  }

  closeSummaryPopup(){
    this.summaryPopup = false;  
    this.confirmSummaryPopup = false;
    this.documentComment = '';
    this.summaryAnswer = '';
  }

  async getPatientDetailsById() {
    this.patientDetails = [];
    const finalData = {
      patientId: this.loggedInUserId,
    };
    this.showLoader = true;

    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.getPatientDetailsById, finalData, this.httpOptions)
      .subscribe(
        (res: any) => {
          if(res){
            this.patientDetails = res.commandResult.data.patientInfo;
            this.FirstName = this.userService.capitalizeFirstLetter(res.commandResult.data.patientInfo.FirstName);
            this.LastName = this.userService.capitalizeFirstLetter(res.commandResult.data.patientInfo.LastName);
            this.showLoader = false;
          }
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  async updatePatientDetailsByPhysician() {
    this.bmi = this.BMI_calculator();
    const finalData = {
      loggedInUserId: this.loggedInUserId,
      patientId: this.loggedInUserId,
      heightInFeet: this.patientDetails.HeightInFeet,
      heightInInch: this.patientDetails.HeightInInch,
      weight: this.patientDetails.Weight,
      bmi: this.bmi
    };
    this.showLoader = true;

    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.updatePatientDetailsByPhysician, finalData, this.httpOptions)
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
          window.location.reload();
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  async goToDashboard() {
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.ushfComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/dashboard/' +
        this.activatedRoute.snapshot.paramMap.get('loggedInUserId'),
      ]);
    // }
  }

  async getPatientsTreatingDoctorsList() {
    this.documentsLength = 0;
    const finalData = {
      patientId: this.loggedInUserId,
      
    };
    this.showLoader = true;
    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.getPatientsTreatingDoctorsList, finalData, this.httpOptions)
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
          if (res.commandResult.data.doctorsList.length > 0){
            this.patientsTreatingDoctorsList = res.commandResult.data.doctorsList;
          }
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  async getPatientsNonTreatingDoctorsList() {
    this.documentsLength = 0;
    const finalData = {
      patientId: this.loggedInUserId,
      
    };
    this.showLoader = true;
    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.getPatientsNonTreatingDoctorsList, finalData, this.httpOptions)
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
          if (res.commandResult.data.nonTreatingDoctorsList.length > 0){
            this.patientsNonTreatingDoctorsList = res.commandResult.data.nonTreatingDoctorsList;
          }
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  fnFindAllDocuments(docType) {
    this.documentsLength = 0
    const finalData = {
        patientId: this.loggedInUserId,
        company_slug: ConstantHelper.COMPANY_SLUG,
        userRoleId: 2,
    };
    this.showLoader = true;

    this.http
        .post(
            this.appConfig.getPhpUrl() + ApiHelper.php_fetchAllDocumentsForPatientHistory,
            finalData,
            this.httpOptions
        )
        .subscribe(
            (apiResponse) => {
                this.resultJsonObj = apiResponse;
                if (this.resultJsonObj.commandResult.data.documentList.length > 0) {
                  this.allDocumentsFlag = true;
                    if (docType != '0') {
                        this.documentsList = this.resultJsonObj.commandResult.data.documentList.filter(doc => doc.DocumentCategoryId == docType);
                    } else {
                        this.documentsList = this.resultJsonObj.commandResult.data.documentList;
                    }
                    // Update the array length after filtering
                    this.documentsLength = this.documentsList.length;
                } else {
                    this.documentsLength = 0;
                }
                this.showLoader = false;
            },
            (err) => {
                // Handle error if needed
            }
        );
}


  async askQuestion(){
    const extension = await this.checkDocumentType(this.selectedAiSummary.DocumentUrl);
    this.generateSummary(this.selectedAiSummary.DocumentUrl, extension);
    this.questionToSummary = '';
  }

  async checkDocumentType(url: string): Promise<string> {
    this.showLoader = true;
    try {
      const response = await this.http.get(url, { responseType: 'blob' }).toPromise();
      const contentType = response.type;
      if (contentType === 'application/pdf') {
        this.showLoader = false;
        return 'PDF';
      } else if (contentType.startsWith('image/')) {
        this.showLoader = false;
        return 'Image';
      } else if (contentType.startsWith('video/')) {
        this.showLoader = false;
        return 'Video';
      } else {
        this.showLoader = false;
        return 'Unknown';
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      throw error;
    }
  }

  summarizeReport(documentUrl: string, extension: string): Observable<any> {
    const Pdfpayload = {
      question: this.questionToSummary ? this.questionToSummary : 'Summarize the report',
      pdf_blob_urls: [documentUrl]
    };
  
    const videoPayload = {
      video_data: documentUrl,
      video_question: this.questionToSummary ? this.questionToSummary : "if it is MRI/CT scan or X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points"
    }
  
    const imagePayload = {
      img_data: documentUrl,
      question: this.questionToSummary ? this.questionToSummary : "For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points" 
    }
  
    if(extension == 'PDF'){
      return this.http.post<any>(this.pdfApiUrl, Pdfpayload);
    } else if (extension == 'Image'){
      return this.http.post<any>(this.imageApiUrl, imagePayload);
    } else if (extension == 'Video'){
      return this.http.post<any>(this.videoApiUrl, videoPayload);
    }
  }
  
  async generateSummary(documentUrl: string, extension: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        this.showLoader = true;
        this.summarizeReport(documentUrl, extension).subscribe(
            (response) => {
                this.showLoader = false;
                this.isSummaryAnswer = true;
                this.summaryAnswer = response.answer;
                resolve(); // Resolve the Promise when summary is generated
            },
            (error) => {
                this.showLoader = false;
                console.error(error);
                reject(error); // Reject the Promise if there's an error
            }
        );
    });
}



  confirmOrObjectionSummary(action: string) {
    const activity = (action == 'confirmation') ? 'confirmation' : 'objection';
    const activityType = (action === 'confirmation') ? 1 : 0;

    const finalData = {
        documentId: +this.selectedAiSummary.RecordId,
        userId: +this.loggedInUserId,
        activity: activity,
        documentComment: this.documentComment,
        activityType: activityType
    };
    this.showLoader = true;
    this.http
        .post(this.appConfig.getLambdaUrl05() + ApiHelper.documentConfirmObjectionPatientHistory, finalData, this.httpOptions)
        .subscribe(
            (apiResponse) => {
                this.showLoader = false;
                window.location.reload();
            },
            (err) => {
                // Handle error
            }
        );
    this.closeSummaryPopup();
}


  getConfirmationObjectionComments(documentId){
    this.documentCommentDetails = [];
    const finalData = {
      userId: +this.loggedInUserId,
      documentId: +documentId
    };
    this.showLoader = true;

    this.http
      .post(this.appConfig.getLambdaUrl05() + ApiHelper.getDocumentConfirmObjectionPatientHistory, finalData, this.httpOptions)
      .subscribe(
        (res: any) => {
          if(res){
            if(res.commandResult.data.commentsInfo.length > 0){
              this.documentCommentDetails = res.commandResult.data.commentsInfo;
              this.showLoader = false;
              this.documentCommentDetailsLength = true
            } else {
              this.showLoader = false;
              this.documentCommentDetailsLength = false;
            }
          }
        },
        (err) => {
          this.showLoader = false;
          console.error(err);
        }
      );
  }

  async generateAiSummaryByClick() {
    try {
        const extension = await this.checkDocumentType(this.selectedAiSummary.DocumentUrl);
        await this.generateSummary(this.selectedAiSummary.DocumentUrl, extension);
        await this.saveAiSummary();
    } catch (err) {
        console.error(err);
    }
  }

async saveAiSummary() {
    try {
        const finalData = {
            documentId: this.selectedAiSummary.RecordId,
            aiSummary: this.summaryAnswer
        };
        this.showLoader = true;
        await this.http.post(this.appConfig.getLambdaUrl05() + ApiHelper.generateAiSummaryByClick, finalData, this.httpOptions).toPromise();
        this.showLoader = false;
        window.location.reload();
    } catch (err) {
        console.error(err);
    }
}

closeFacilityDetailsPopup(){
  this.openFacilityDetailsPopup = false;
}

openFacilityDetailsPopupWindow(documentId){
  this.facilityDetailsArray = [this.documentsList.find(
    (e) => e.RecordId == documentId
    )];
  this.openFacilityDetailsPopup = true;
}

getDocumentCategories(){
this.showLoader = true;
this.http
    .post(
        this.appConfig.getLambdaUrl05() + ApiHelper.getDocumentCategories,
        this.httpOptions
    )
    .subscribe(
        (apiResponse: any) => {
            this.resultJsonObj = apiResponse.commandResult.data.documentCategories;
            this.showLoader = false;
        },
        (err) => {
            // Handle error if needed
        }
    );
}

openDictationSummary(SummaryUrl: string): void {
  if (SummaryUrl) {
    window.open(SummaryUrl, '_blank');
  } else {
    console.error('Summary URL is not available.');
  }
}

findPracticeDocuments(){
  this.documentsLength = 0;
  const finalData = {
    userId: this.loggedInUserId
  };
  this.showLoader = true;

  this.http
      .post(
          this.appConfig.getLambdaUrl05() + ApiHelper.findPracticeDocuments,
          finalData,
          this.httpOptions
      )
      .subscribe(
          (apiResponse: any) => {
            if(apiResponse.commandResult.data.docsList.length > 0){
              this.practiceDocumentsFlag = true;
              this.practiceDocumentsList = apiResponse.commandResult.data.docsList
            }
              this.showLoader = false;
          },
          (err) => {
              // Handle error if needed
          }
      );
}

}